import React from 'react'
// import "./Privacy.css"
import { MainWrapper, MidWrapper } from "../../components/userprofile/styled";


const Privacy = () => {

const handleCallButtonClick = () => {
    window.open(`mailto:admin@cuirato.com`);
};

    return (
      
            
            <MainWrapper>  
                <h3 className='Main-title'>Privacy Policy</h3>

              <MidWrapper>
                <p>cuirato.com is owned and operated by Cuirato Food Technologies Private Limited. cuirato.com values your privacy. This notice describes what information we collect, how we use it, and, if necessary, those with whom we share it.  By using cuirato.com, you are accepting the practices described in this Privacy Policy. This Privacy Policy may change from time to time. Your continued use of the platform and the website after we make changes are deemed to be acceptance of those changes, so please check the policy periodically for updates. This Privacy Policy has been developed and is maintained in accordance with all applicable federal and international laws and regulations and specifically with the California Online Privacy Protection Act (CalOPPA – U.S regulation), the GDPR (General Data Protection Regulation – European regulation) and the personal data protection laws of India.
                    This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
                    We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. Interpretation and Definitions
                    Interpretation
                    The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in the singular or in the plural.
                </p>
                <br />
                <p>Definitions</p>
                <br />
                <h4>For the purposes of this Privacy Policy:</h4>
                <br />
                <p>Account means a unique account created for You to access our Service or parts of our Service.
                    Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Cuirato Food Technologies Private Limited.

                    Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                    Personal Data is any information that relates to an identified or identifiable individual.
                    Service refers to the App.
                    Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
                    Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.
                    Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                    App refers to the mobile device application (the “Driver App" or the “Customer App") and related services.
                    You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                    Collecting and Using Your Personal Data
                </p>
                <br />
                <h4>Types of Data Collected</h4>
                <br />
                <p>Personal Data</p>
                <br />
                <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
                </p>
                <br />
                <p>Email address</p>
                <p>First name and last names</p>
                <p>Phone number</p>
                <p>Address, State, Province, ZIP/Postal code, City</p>
                <p>Usage Data</p>
                <p>Location Data</p>
                <p>Usage Data</p>
                <br />
                <p>Usage Data is collected automatically when using the Service.</p>
                <br />
                <p>
                    Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
                </p>
                <br />
                <p>
                    When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
                </p>
                <br />
                <p>
                    We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
                </p>
                <br />
                <h4>Tracking Technologies and Cookies</h4>
                <br />
                <p>
                    We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
                </p>
                <br />
                <p>Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</p>
                <p>Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
                    Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
                </p>
                <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
                <br />
                <p>Necessary / Essential Cookies</p>
                <p>Type: Session Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
                </p>
                <p>Cookies Policy / Notice Acceptance Cookies</p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                <p>Functionality Cookies</p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
                </p>
                <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
                <br />
                <h4>Use of Your Personal Data</h4>
                <br />
                <p>The Company may use Personal Data for the following purposes:</p>
                <br />
                <p>To provide and maintain our Service, including to monitor the usage of our Service.
                    To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
                    For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
                    To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
                    To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
                    To manage Your requests: To attend and manage Your requests to Us.
                    For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
                    For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.
                    We may share Your personal information in the following situations:
                </p>
                <br />
                <p>With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</p>
                <p>For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
                    With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
                    With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.
                    With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.
                    With Your consent: We may disclose Your personal information for any other purpose with Your consent.
                </p>
                <h4>Retention of Your Personal Data</h4>
                <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                </p>
                <p>
                    The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
                </p>
                <p>Transfer of Your Personal Data.</p>
                <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
                <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                <h4>Disclosure of Your Personal Data</h4>
                <p>Business Transactions: If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                <p>GOOGLE Analytics. We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the website and platform and improve our service.
                </p>
                <p>Please consult Google’s privacy policy here:</p>
                <a href="https://policies.google.com/privacy">Click here-</a>
                <p>Location Data for the Customer App and the Driver App:</p>
                <p>The App collects your location data in order to provide you with the services offered by the App. The data is collected while the App is open and also when the App is not open but is running in the background. This data is crucial for providing services like order allocation, route optimization, or live order tracking. If you do not want Us to collect this data, you can choose to “log out” from the app. Please note that “logging in” again permits Us to collect your location data.
                </p>
                <p>
                    EU MEMBERS’ RIGHTS (GDPR COMPLIANT)
                    If you are habitually located in the European Union, you have the right to access, rectify, download or erase your information, as well as the right to restrict and object to certain processing of your information. While some of these rights apply generally, certain rights apply only in certain limited circumstances.
                </p>
                <p>We describe these rights below:</p>
                <p>Access and portability: to access and know what information is stored in our servers, you can send us your request through our contact information.
                    Rectify, Restrict, Limit, Delete: You can also rectify, restrict, limit or delete much of your information.
                </p>
                <p>Object: When we process your information based on our legitimate interests as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have compelling legitimate reasons to continue processing it or where it is necessary for legal reasons.
                    Revoke consent: Where you have previously given your consent, such as to allow us to process and store your personal information, you have the right to revoke your consent to the processing and storage of your information at any time. For example, you may withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a legal basis for doing so or if your withdrawal of consent was limited to certain processing activities.
                    Complaint: If you wish to file a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users can exercise all these rights by contacting us through the contact information or the contact page.
                    Additionally, if you are a European resident, we note that we are processing your information in order to fulfil contracts we might have with you or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe.
                </p>
                <p>You can see more about these rights at: <a href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/rights-citizens_en
"> here-</a> </p>
                <p>CALIFORNIA ONLINE PRIVACY PROTECTION ACT COMPLIANCE (CalOPPA)
                    CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared.
                </p>
                <p>Because we value your privacy, we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act (CalOPPA).</p>
                <p>According to the California Online Privacy act, you have the following rights:</p>
                <p>Know what personal data is being collected.</p>
                <p>Know whether your personal data is sold or disclosed and to whom.</p>
                <p>Request to delete any personal information about you.</p>
                <p>Not be discriminated against for exercising their privacy rights.</p>
                <br />
                <p>Law enforcement</p>
                <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <p>
                    Other legal requirements
                </p>
                <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                <p>Comply with a legal obligation</p>
                <p>Protect and defend the rights or property of the Company</p>
                <p>Prevent or investigate possible wrongdoing in connection with the Service
                </p>
                <p>Protect the personal safety of Users of the Service or the public
                </p>
                <p>Protect against legal liability
                </p>
                <h4>Security of Your Personal Data</h4>
                <p>
                    The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
                </p>
                <br />
                <p>Children's Privacy</p>
                <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
                </p>
                <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
                <br />
                <p>Links to Other Websites</p>
                <p>
                    Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.

                </p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                <h4>Changes to this Privacy Policy</h4>
                <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
                </p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                </p>
                <h5>Contact Us</h5>
                <p>If you have any questions about this Privacy Policy, You can contact us:
                </p>
                <p>By email: <b onClick={handleCallButtonClick} >admin@cuirato.com</b> </p>
            {/* </div> */}
            </MidWrapper>
            </MainWrapper>
          
    )
}

export default Privacy;