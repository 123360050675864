import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { chefActions } from "../../store/chefSlice";
import { API_TEST } from "../../store/constants";
import ChefCardInfoSlider from "./ChefCardInfoSlider";
import ChefCardProfile from "./ChefCardProfile";
import ChefCardSlider from "./ChefCardSlider";
import ChefMenuCategorySlider from "./ChefMenuCategorySlider";
import MenuButton from "./MenuButton";
import { fetchChefWaitingTime } from "../../store/chefActions";

const ChefCard = (props) => {
  let chefProfileDetails = props.details;
  const dispatch = useDispatch();
  const activeOrderDetails = useSelector((state) => state.auth.orderDetails);
  const chefTimeData = useSelector((state) => state.chef.chefTime);
  const currentChefsTime = chefTimeData[chefProfileDetails._id];
  
   const chefWaitTime = currentChefsTime?.chefWaitTime || 0;
  
  const labels = {
    experienceDesc: "Total experience",
    qualificationLabel: "Educational Qualification",
    experienceImage: "images/experience.png",
    cfo: "images/rupee-indian.png",
    success: "images/goal.png",
    timing: "images/shift-timing.png",
    delivery: "images/fast-delivery.png",
  };
  

  const experienceDetails = chefProfileDetails.pastExperiences.map((exp) => {
    let year = exp.years > 1 ? " years" : " year"
    return {
      img: exp.brandImg,
      header: exp.brandName,
      desc: exp.city + ", " + Math.ceil(exp.years) + year,
    };
  });

  const { isMenuActive } = chefProfileDetails;

  const fetchChefTimeApiCall = async () => {
    try {
      const response = await axios.get(
        `${API_TEST}getDeliveryDelayTimeByChef/${chefProfileDetails._id}`
      );
      const responseData = response?.data?.data || {};
      // setChefWaitTime(responseData?.chefWaitTime || 0)
    } catch (error) {
      console.error("Error making API call:", error.message);
      throw error;
    }
  }
  
  useEffect(() => {
    if(activeOrderDetails?._id){
      dispatch(fetchChefWaitingTime(chefProfileDetails._id));
    }
    // fetchChefTimeApiCall()
  }, [activeOrderDetails?._id])


  return (
    <ChefCardSlider>
      <div className="profile-organization" onTouchStart={() => dispatch(chefActions.setRunDemo())}>
        <ChefCardProfile chefWaitTime={chefWaitTime} item={chefProfileDetails} labels={labels} />
        <ChefCardInfoSlider
          header="Professional Experience"
          items={experienceDetails}
          scrollerBind={props.scrollerBind}
        />
      </div>
      <div className="menu-action">
        {isMenuActive && (
          <ChefMenuCategorySlider
            header="Menu Highlights"
            id={chefProfileDetails._id}
            scrollerBind={props.menuScrollerBind}
          />
        )}
        <MenuButton
          img="images/icon-menu.svg"
          alt="menu"
          title={isMenuActive ? "Explore Menu" : "Coming soon"}
          chefId={chefProfileDetails._id}
          isMenuActive={isMenuActive}
        />
      </div>
    </ChefCardSlider>
  );
};

export default ChefCard;
