import React from 'react'
import { MainWrapper, MidWrapper } from "../../components/userprofile/styled";

// import "./Cancellation.css"

const Cancellation = () => {
    return (
            <>
            <MainWrapper> 
                <h3 className='Main-title'>Cancellation and Refund Policy </h3>

                <MidWrapper>
                <p>Any Capitalized terms used but not defined herein shall have the meaning assigned to them under the Terms of Use which govern your use of our website www.cuirato.com (the “Website”) and our ‘Cuirato’ application for mobile and handheld devices (the “App”). The Website and the App are jointly referred to as the “Platform”.
                </p>
                <br />
                <h4>Customer Cancellation</h4>
                <br />
                <p className='pd-left'>As a general rule Buyer shall not be entitled to cancel Order once placed. Buyer may choose to cancel Order only within one-minute of the Order being placed. However, subject to Buyer’s previous cancellation history, Cuirato reserves the right to deny any refund to Buyer pursuant to a cancellation initiated by Buyer even if the same is within one-minute followed by suspension of account, as may be necessary in the sole discretion of Cuirato.
                    If Buyer cancels his/her Order after one minute of placing it, Cuirato shall have a right to collect a penalty of 100% of the Order amount for breach of contract terms as a compensation for the damages suffered by Cuirato, with a right to either not to refund the Order value in case Buyer’s Order is prepaid or recover from the Buyer’s subsequent Order in case his/her Order is postpaid, to compensate the Merchants and PDPs.
                </p>
                <h4>Non-Customer Cancellation</h4>
                <p>Cuirato reserves the right to collect a penalty for the Orders constrained to be cancelled by Cuirato for reasons not attributable to Cuirato, including but not limited to:</p>
                <p className="pd-left">
                    in the event if the address provided by Buyer is either wrong or falls outside the delivery zone;
                    failure to contact Buyer by phone or email at the time of delivering the Order booking;
                    failure to deliver Buyer Order due to lack of information, direction or authorization from Buyer at the time of delivery; or
                    unavailability of all the items ordered by Buyer at the time of booking the Order; or
                    unavailability of all the items ordered by Buyer at the time of booking the Order. However, in the unlikely event of an item in an Order being unavailable, Cuirato will contact the Buyer on the phone number provided to us at the time of placing the Order and inform Buyer of such unavailability. In such an event Buyer will be entitled to cancel the entire Order and shall be entitled to a refund to an amount upto 100% of the Order value.

                </p>
                <p>In case of cancellations for the reasons attributable to Cuirato or the Merchant or PDP, Cuirato shall not collect any penalty from the Buyer.</p>
                <h4>Refunds</h4>
                <p>Buyer may be entitled to a refund for prepaid Orders.  Cuirato retains the right to retain the penalty payable by the Buyer in Section I(2) from the amount refundable to him/her. The Buyer shall also be entitled to a refund of proportionate value in the event packaging of an item in an Order or the complete Order, is either tampered or damaged and the Buyer refuses to accept at the time of delivery for the said reason;
                </p>
                <p>Buyer may be entitled to a refund upto 100% of the Order value if PDP fails to deliver the Order due to a cause attributable to either PDP or Cuirato, however such refunds will be assessed on a case to case basis by Cuirato.
                    Our decision on refunds shall be final and binding.
                    All refund amounts shall be credited to Buyer’s account as may be stipulated as per the payment mechanism of Buyer’s choice, the estimated timelines are detailed as below, in case Buyer don't choose to credit it to Buyer’s wallet with his/her Cuirato Account;
                </p>
                <br />
                <div style={{ overflowX: "auto" }}>
                    <table>
                        <tr>
                            <th>Process</th>
                            <th>Payment Method</th>
                            <th>Refund Source</th>
                            <th>TAT</th>

                        </tr>
                        <tr>
                            <td>Order Edit
                                /Cancellation/Compensation/Payment Failure
                            </td>
                            <td>Net Banking</td>
                            <td>Source</td>
                            <td>5-7 Business Days</td>

                        </tr>
                        <tr>
                            <td></td>
                            <td>Debit/Credit Cards</td>
                            <td>
                                Source
                            </td>
                            <td>5-7 Business Days</td>

                        </tr>
                        <tr>
                            <td></td>
                            <td>UPI</td>
                            <td>Source</td>
                            <td>2 Hours</td>


                        </tr>
                        <tr>
                            <td></td>
                            <td>Amazon Pay (Wallet)</td>
                            <td>Source</td>
                            <td>5-7 Business Days</td>


                        </tr>
                        <tr>
                            <td></td>
                            <td>Amazon Pay (CC/DC/NB)</td>
                            <td>Source</td>
                            <td>5-7 Business Days</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phone Pe (Wallet)</td>
                            <td>Source</td>
                            <td>5-7 Business Days</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Phone Pe (CC/DC/NB)</td>
                            <td>Source</td>
                            <td>5-7 Business Days</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Wallets-Paytm/Mobikwik/Freecharge</td>
                            <td>Source</td>
                            <td>2 Hours</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Lazy Pay</td>
                            <td>Source</td>
                            <td>2 Hours</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Sodexo</td>
                            <td>Source</td>
                            <td>5-7 Business Days</td>
                        </tr>
                    </table>
                </div>
                <br />
                <p>In case of payment at the time of delivery, Buyer will not be required to pay for:
                </p>
                <p className='pd-left'>Orders where packaging is either tampered or damaged at the time of delivery;</p>
                <p className='pd-left'>Wrong Order being delivered; or</p>
                <p className='pd-left'>Items missing from Buyer’s Order at the time of delivery.
                </p>
                <p>Provided the same is communicated to Cuirato Customer Care through the Platform before the Order is marked delivered.
                </p>

                <h4>Specific Terms with respect to Use of Platform for purchase of alcoholic beverages</h4>
                <p>Cancellation: As a general rule the Buyer shall not be entitled to cancel his/her Order once placed. If the Buyer cancels his/her Order, Cuirato shall have a right to collect the Buyer 100% of the Order amount as the cancellation fee, with a right to either not to refund the Order value in case the Buyer’s Order is prepaid or recover from the Buyer’s subsequent Order(s) in case Buyer’s Order is postpaid, to compensate the Merchants and PDPs.</p>
                <p>Non-Customer Cancellation: Cuirato reserves the right to collect the Buyer cancellation fee for the Orders constrained to be cancelled by Cuirato for reasons not attributable to Cuirato or Merchant, including but not limited to:
                </p>
                <p className='pd-left'>in the event if the address provided by the Buyer is either wrong or falls outside the Cuirato’s delivery zone;
                    failure to contact the Buyer by phone or email at the time of delivering the Order;
                    failure to deliver the Buyer’s Order due to lack of information, direction or authorization from the Buyer at the time of delivery; or
                    the Buyer’s failure to provide a valid OTP to the PDP for receiving delivery
                </p>
                <p>Refunds: The Buyer may be entitled to a refund for prepaid Orders, post deduction of cancellation fee, if any for reasons mentioned above or in a manner as deemed fit by Cuirato in its sole discretion, if refund has been requested due to the following reasons:
                </p>
                <p className='pd-left'>If the Order could not be delivered within 2 hours;
                    If the Merchant cancels the Order due to reasons not attributable to the Buyer, including but not limited to store being closed, non-availability of items, store not servicing online Orders, overcrowding at store, etc.;
                    If Cuirato cancels the Order due to reasons not attributable to the Buyer, including but not limited to non-availability of PDPs, etc.
                </p>
                <p>The Buyer may be entitled to refund due to the aforesaid reasons upto 100% of the Order value depending upon the nature of issue. Cuirato reserves the right to consider the cancellation and refund request and determine if such cancellation and refund request satisfy any of the aforesaid conditions, in which case Cuirato shall process the cancellation request and refund to Buyer.
                </p>
                <p> <b>Important Note 1:</b>  The Buyer shall verify his/her Order and the products before providing OTP to the PDP and any issue with respect to the product or Buyer’s Order shall be notified immediately, and cancellation request shall be placed before providing OTP to the PDP. Once OTP is provided to the PDP, it shall be deemed that the Buyer has accepted delivery of his/her Order and once he/she has accepted delivery, the Buyer cannot cancel the Order and/or claim refund.</p>
                <p> <b>Important Note 2:</b>  In case of the complaint of any spurious product, the liability shall solely lie with the Merchant selling the product. Cuirato is merely facilitating the transactions between the Merchant and the Buyer and therefore, Cuirato shall assume no liability with respect to the products sold by the Merchant. Cuirato strictly discourages dealing of any spurious product on its Platform and shall reserve the right to report such incidents to the concerned authorities for appropriate legal actions.</p>



            </MidWrapper>
            </MainWrapper>
            </>
        
    )
}

export default Cancellation;