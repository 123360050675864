import React from 'react';
import Sheet from 'react-modal-sheet';
import "./vegChoiceModal.css"
import { toast } from 'react-toastify';
import InstallEmoji from '../../assets/ReactSvg/InstallEmoji';


const VegChoiceModal = ({ isOpen, setOpen, setFoodChoiceState }) => {

    const onCloseModal = () => {
        toast.error("Please select a your preference")
    }

    return (
        <Sheet
            springConfig={{
                stiffness: 300,
                damping: 30,
                mass: 0.2
            }}
            isOpen={isOpen}
            onClose={onCloseModal}
            // snapPoints={[500, 0]}
            detent="content-height"
            className='install-modal-popup vegchoice-modal-sheet customReactSheet'
            onSnap={(snapIndex) =>
                console.log("> Current snap point index:", snapIndex)
            }
        >
            <Sheet.Container>
                <Sheet.Content>
                    
                    <div className='install-modal-wrapper'>
                    <div className='install-modal-emogy'>
                        <InstallEmoji />
                    </div>
                        <h5 style={{paddingTop:0}} className='install-app-label'>What's your preference?</h5>
                        <div style={{display:"flex", columnGap:10, marginTop:15}}>
                        <button 
                        onClick={() => {
                            localStorage.setItem("foodChoice", "VEG")
                            setFoodChoiceState("VEG")
                            setOpen(false)
                        }}
                        style={{ background: "#7d9a6f", marginTop: 10, borderColor:"#7d9a6f" }} className='download-now-button-modal'>Veg Dishes</button>
                        <button 
                        onClick={() => {
                            localStorage.setItem("foodChoice", "NONVEG")
                            setFoodChoiceState("NONVEG")
                            setOpen(false)
                        }}
                         style={{ marginTop: 10, background: "#670000", borderColor:"#670000" }} className='download-now-button-modal'>All Dishes</button>
                        </div>
                    </div>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={onCloseModal} />
        </Sheet>
    )
}

export default VegChoiceModal