import React from "react";
import "./chef-cards.css";
import { Tooltip } from "antd";


const ChefProfilePic = ({ onClick, img, unlocked, chefWaitTime }) => {
  const chefWaitTimeCount = chefWaitTime || 0;

  return (
    <div onClick={onClick} className="profile-right">
      <figure className="profile-figure">
        {chefWaitTimeCount > 50 ? <img className="lock-icon" style={{ width: 30, height: 30 }} src={"images/Icons/busyIcon.svg"} alt="" /> :   <img className="lock-icon" style={{ width: 30, height: 30 }} src={"images/Icons/flash.svg"} alt="" />}
        <span className="img-ring-red">
          <img className="profile-figure-img" src={img} alt="profile image" />
        </span>
      </figure>
    </div>
  );
};

export default ChefProfilePic;
