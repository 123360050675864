import React from "react";

function CloseModalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect
        width="29.66"
        height="29.66"
        x="0.17"
        fill="#fff"
        fillOpacity="0.3"
        rx="14.83"
      ></rect>
      <mask
        id="mask0_4503_48"
        style={{ maskType: "alpha" }}
        width="16"
        height="17"
        x="7"
        y="6"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M7 6.83H23V22.83H7z"></path>
      </mask>
      <g mask="url(#mask0_4503_48)">
        <path
          fill="#fff"
          d="M15 15.824l-3.478 3.478a.673.673 0 01-.497.195.673.673 0 01-.496-.195.673.673 0 01-.195-.497c0-.201.065-.367.195-.497l3.478-3.478-3.478-3.478a.673.673 0 01-.195-.496c0-.201.065-.367.195-.497a.673.673 0 01.496-.195c.202 0 .367.065.497.195L15 13.837l3.478-3.478a.673.673 0 01.497-.195c.2 0 .366.065.497.195.13.13.195.296.195.497a.673.673 0 01-.195.496l-3.478 3.478 3.478 3.478c.13.13.195.296.195.497a.673.673 0 01-.195.497.673.673 0 01-.497.195.673.673 0 01-.497-.195L15 15.824z"
        ></path>
      </g>
    </svg>
  );
}

export default CloseModalIcon;
