import React, { useEffect, useState } from "react";
import BottomBar from "../BottomBar";
import MenuTopBar from "../menu/MenuTopBar";
import AppContainer from "../util/AppContainer";
import { useSelector, useDispatch } from "react-redux";
import "../dishBook/dishBook.css";
import { bottomBarActions } from "../../store/bottomBarSlice";
import "../menu/browse-menu-button.css";
import ReactGA from "react-ga4";
import { setBookingData } from "../../store/loginActions";
import BackBtnSvg from "../../assets/ReactSvg/BackBtnSvg";
import Privacy from "../../constant/Privacy&Policy/Privacy";
import Terms from "../../constant/Terms&Condition/Terms";
import Cancellation from "../../constant/Cancellation/Cancellation";

const LegalView = () => {
  const [screen, setScreen] = useState(0);
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/cart" });
    ReactGA.event({
      category: "tab",
      action: "legal",
    });
  }, []);
  const user = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBookingData(user.userid));
  }, [dispatch, user.userid]);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
  };

  const onBackClick = () => {
    switchTab("profile");
  };

  const getContent = () => {
    switch (screen) {
      case 1:
        return <Privacy />;
      case 2:
        return <Terms />;
      case 3:
        return <Cancellation />;
    }
  };

  return (
    <AppContainer>
      <div className="cart-wrapper">
        <div className="cart-top-bar">
          <MenuTopBar
            name="Legal Details"
            cuisine=""
            notMenu={true}
            onBackClick={onBackClick}
          />
        </div>
        <section className="section-food-accordian">
          <div className="food-accordian">
            {screen === 0 && (
              <>
                <p className="cart-header">Legal Details</p>
                <div>
                  <div
                    onClick={() => setScreen(1)}
                    className="order-history_container"
                  >
                    <h1 className="order-history_container_header">
                      Privacy And Policy
                    </h1>
                  </div>
                  <div
                    onClick={() => setScreen(2)}
                    className="order-history_container"
                  >
                    <h1 className="order-history_container_header">
                      Terms and Conditions
                    </h1>
                  </div>
                  <div
                    onClick={() => setScreen(3)}
                    className="order-history_container"
                  >
                    <h1 className="order-history_container_header">
                      Cancellation and Refund Policy
                    </h1>
                  </div>
                </div>
              </>
            )}
            {[1, 2, 3].includes(screen) && (
              <>
                <div>
                  <button
                    onClick={() => setScreen(0)}
                    type="button"
                    className="btn back-btn"
                  >
                    <BackBtnSvg />
                  </button>
                </div>
                <div style={{ position: "relative", top: "-40px" }}>
                  {getContent()}
                </div>
              </>
            )}
          </div>
        </section>
      </div>
      <BottomBar />
    </AppContainer>
  );
};

export default LegalView;
