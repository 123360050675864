import * as React from "react";
const CallIcon = (props) => {
  const { fill = "#fff" } = props
  return <svg
  fill={fill}
  id="Capa_1555"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  width="16px"
  height="16px"
  viewBox="0 0 8.298 8.298"
  xmlSpace="preserve"
  {...props}
>
  <g>
    <path d="M3.182 5.129c0.744 0.739 1.606 1.445 1.944 1.104 0.484 -0.488 0.782 -0.912 1.857 -0.055 1.074 0.858 0.254 1.434 -0.215 1.907 -0.542 0.546 -2.569 0.038 -4.578 -1.956C0.182 4.134 -0.342 2.111 0.2 1.565c0.47 -0.472 1.041 -1.298 1.906 -0.229 0.866 1.068 0.443 1.37 -0.041 1.858 -0.338 0.341 0.374 1.197 1.118 1.936zm1.079 -3.525s-0.225 -0.035 -0.382 0.122a0.438 0.438 0 0 0 0.002 0.611 0.486 0.486 0 0 0 0.243 0.122c0.277 0.052 0.694 0.14 1.139 0.584 0.444 0.444 0.532 0.862 0.584 1.139 0 0 0.022 0.142 0.122 0.243a0.438 0.438 0 0 0 0.611 0.002c0.158 -0.158 0.122 -0.382 0.122 -0.382a2.952 2.952 0 0 0 -2.442 -2.442zm2.106 0.335c0.814 0.814 1.162 1.738 1.049 2.487 0 0 -0.039 0.237 0.115 0.392a0.436 0.436 0 0 0 0.614 0.002c0.105 -0.105 0.128 -0.275 0.128 -0.275 0.094 -0.647 -0.024 -1.95 -1.293 -3.218C5.712 0.058 4.41 -0.06 3.763 0.034c0 0 -0.17 0.022 -0.275 0.128a0.435 0.435 0 0 0 0.002 0.614c0.155 0.155 0.392 0.115 0.392 0.115 0.749 -0.113 1.674 0.234 2.488 1.049z" />
  </g>
</svg>
};
export default CallIcon;
