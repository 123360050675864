import moment from 'moment';

const currentDate = moment();

const timeSlots = [
    // { value: currentDate.clone().set({ hour: 11, minute: 0 }).toDate(), label: "11:00 am to 11:30 am" },
    // { value: currentDate.clone().set({ hour: 11, minute: 30 }).toDate(), label: "11:30 am to 12:00 pm" },
    // { value: currentDate.clone().set({ hour: 12, minute: 0 }).toDate(), label: "12:00 pm to 12:30 pm" },
    { value: currentDate.clone().set({ hour: 12, minute: 30 }).toDate(), label: "12:30 pm to 01:00 pm" },
    { value: currentDate.clone().set({ hour: 13, minute: 0 }).toDate(), label: "01:00 pm to 01:30 pm" },
    { value: currentDate.clone().set({ hour: 13, minute: 30 }).toDate(), label: "01:30 pm to 02:00 pm" },
    { value: currentDate.clone().set({ hour: 14, minute: 0 }).toDate(), label: "02:00 pm to 02:30 pm" },
    { value: currentDate.clone().set({ hour: 14, minute: 30 }).toDate(), label: "02:30 pm to 03:00 pm" },
    { value: currentDate.clone().set({ hour: 15, minute: 0 }).toDate(), label: "03:00 pm to 03:30 pm" },
    { value: currentDate.clone().set({ hour: 15, minute: 30 }).toDate(), label: "03:30 pm to 04:00 pm" },
    { value: currentDate.clone().set({ hour: 16, minute: 0 }).toDate(), label: "04:00 pm to 04:30 pm" },
    { value: currentDate.clone().set({ hour: 16, minute: 30 }).toDate(), label: "04:30 pm to 05:00 pm" },
    { value: currentDate.clone().set({ hour: 17, minute: 0 }).toDate(), label: "05:00 pm to 05:30 pm" },
    { value: currentDate.clone().set({ hour: 17, minute: 30 }).toDate(), label: "05:30 pm to 06:00 pm" },
    { value: currentDate.clone().set({ hour: 18, minute: 0 }).toDate(), label: "06:00 pm to 06:30 pm" },
    { value: currentDate.clone().set({ hour: 18, minute: 30 }).toDate(), label: "06:30 pm to 07:00 pm" },
    { value: currentDate.clone().set({ hour: 19, minute: 0 }).toDate(), label: "07:00 pm to 07:30 pm" },
    { value: currentDate.clone().set({ hour: 19, minute: 30 }).toDate(), label: "07:30 pm to 08:00 pm" },
    { value: currentDate.clone().set({ hour: 20, minute: 0 }).toDate(), label: "08:00 pm to 08:30 pm" },
    { value: currentDate.clone().set({ hour: 20, minute: 30 }).toDate(), label: "08:30 pm to 09:00 pm" },
    { value: currentDate.clone().set({ hour: 21, minute: 0 }).toDate(), label: "09:00 pm to 09:30 pm" },
    { value: currentDate.clone().set({ hour: 21, minute: 30 }).toDate(), label: "09:30 pm to 10:00 pm" },
    { value: currentDate.clone().set({ hour: 22, minute: 0 }).toDate(), label: "10:00 pm to 10:30 pm" },
    { value: currentDate.clone().set({ hour: 22, minute: 30 }).toDate(), label: "10:30 pm to 11:00 pm" },
    { value: currentDate.clone().set({ hour: 23, minute: 0 }).toDate(), label: "11:00 pm to 11:30 pm" },
    // { value: currentDate.clone().set({ hour: 23, minute: 30 }).toDate(), label: "11:30 pm to 12:00 am" },
    // { value: currentDate.clone().add(1, 'days').set({ hour: 0, minute: 0 }).toDate(), label: "12:00 am to 12:30 am" },
    // { value: currentDate.clone().add(1, 'days').set({ hour: 0, minute: 30 }).toDate(), label: "12:30 am to 01:00 am" },
    // { value: currentDate.clone().add(1, 'days').set({ hour: 1, minute: 0 }).toDate(), label: "01:00 am to 01:30 am" },
    // { value: currentDate.clone().add(1, 'days').set({ hour: 1, minute: 30 }).toDate(), label: "01:30 am to 02:00 am" },
    // { value: currentDate.clone().add(1, 'days').set({ hour: 2, minute: 0 }).toDate(), label: "02:00 am to 02:30 am" },
    // { value: currentDate.clone().add(1, 'days').set({ hour: 2, minute: 30 }).toDate(), label: "02:30 am to 03:00 am" },
    // { value: currentDate.clone().add(1, 'days').set({ hour: 3, minute: 0 }).toDate(), label: "03:00 am to 03:30 am" },
    // { value: currentDate.clone().add(1, 'days').set({ hour: 3, minute: 30 }).toDate(), label: "03:30 am to 04:00 am" },
  ];

export default timeSlots;
