/* eslint-disable no-lone-blocks */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stories from "react-insta-stories";
import ReactGA from "react-ga4";

import "./style.css";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { storieAction } from "../../store/storieSlice";
import { chefActions } from "../../store/chefSlice";
import mixpanel from "mixpanel-browser";
import BackBtnSvg from "../../assets/ReactSvg/BackBtnSvg";
import { menuActions } from "../../store/menuSlice";
// import clevertap from "clevertap-web-sdk";

const SingleStoriePage = () => {
  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/stories" });
    ReactGA.event({
      category: "tab",
      action: "stories",
    });
    window.scrollTo(0, 1);
  }, []);

  const childernData = useSelector((store) => store.storie.storieData || []);
  const from = useSelector((store) => store.storie.from || []);
  const chefIdState = useSelector((store) => store.chef.chefId)
  const dishes = useSelector((state) => state.bottomBar.dishes);
  const chef = useSelector((state) => state.bottomBar.chef);
  const cart = useSelector((state) => state.bottomBar.cart);

  const dispatch = useDispatch();
  const onStoryBackClick = () => {
    switch (from) {
      case "dishes":
        dispatch(bottomBarActions.loadTab({ tabName: "dishes" }));
        mixpanel.track("story-back-to-dishes");
        // clevertap.event.push("story-back-to-dishes");
        return;
      case "cart":
        dispatch(bottomBarActions.loadTab({ tabName: "cart" }));
        mixpanel.track("story-back-to-cart");
        // clevertap.event.push("story-back-to-cart");
        return;
      case "chef":
        dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
        mixpanel.track("story-back-to-chef");
        // clevertap.event.push("story-back-to-chef");
        return;
      case "menu":
        dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
        mixpanel.track("story-back-to-menu");
        // clevertap.event.push("story-back-to-menu");
        return;
      case "dishBook":
        dispatch(bottomBarActions.loadTab({ tabName: "dishBook" }));
        mixpanel.track("story-back-to-dishBook");
        // clevertap.event.push("story-back-to-dishBook");
        return;
      case "orderSummary":
        dispatch(bottomBarActions.loadTab({ tabName: "orderSummary" }));
        mixpanel.track("story-back-to-orderSummary");
        // clevertap.event.push("story-back-to-orderSummary");
        return;
      default:
        dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
        mixpanel.track("story-back-to-chef");
        // clevertap.event.push("story-back-to-chef");
        break;
    }
  };

  const addToCard = () => {
    dispatch(menuActions.setIsMenuClicked(true));
    dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
    if(chefIdState){
      dispatch(chefActions.onMenuClick(chefIdState));
    }
    mixpanel.track("go-to-menu-clicked");
    // clevertap.event.push("go-to-menu-clicked");
  };

  const stories = childernData?.map(({ img, name, description, price, dish, discountMessage = "", dealPrice }) => ({
    url: img,
    duration: 3000,
    content: () => {
      return (
        <div className="single_stories_main">
          <div className="swiper_Top">
            <div style={{ padding: "15px 0" }} className="swiper_Title">
              <div className="swiper_top_back_btn">
                <button
                  onClick={onStoryBackClick}
                  type="button"
                  className="btn back-btn abs-pos"
                >
                  <BackBtnSvg />
                </button>
              </div>
              <div className="swiper_Title_img">
                <img src={img} className="storyImg" />
              </div>
              <p>{name}</p>
            </div>
          </div>
          <div className="swipper_main_img">
            <div className="image-container">
              <img
                src={img}
                className="story-image-container storyImg"
                loading="lazy"
              />
              {from !== "chef" && <div className="gradient"></div>}
            </div>
          </div>
          {from !== "chef" && <div className="swipper_bottom">
            {description && <div style={{ marginBottom: 15 }}>{description}</div>}
            <div className="swipper_bottom_first">
              <div className="items_value">
                <div className="items_price">
                  <h6>Original Price</h6>
                </div>
                <div className="plus_taxes">
                  <h5>
                    <span className="currency">&#8377;</span> <span className={dealPrice && "price-strike"}>{price}</span> 
                    {dealPrice && <>/{dealPrice}</>}
                  </h5>
                  <p>plus taxes</p>
                </div>
              </div>
              <div onClick={addToCard} className="addcart_btn">
                <button>
                  <p>Go to Menu</p>
                  <span>+</span>
                </button>
              </div>
            </div>
            {discountMessage && (
              <h6 className="media-dish-cap orange-text">
                {discountMessage}
              </h6>
            )}
          </div>}
        </div>
      );
    },
  }));

  const onAllStoriesEnd = () => {
    switch (from) {
      case "dishes":
        dispatch(bottomBarActions.loadTab({ tabName: "dishes" }));
        return;
      case "cart":
        dispatch(bottomBarActions.loadTab({ tabName: "cart" }));
        return;
      case "chef":
        dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
        return;
      case "menu":
        dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
        return;
      case "dishBook":
        dispatch(bottomBarActions.loadTab({ tabName: "dishBook" }));
        return;
      case "orderSummary":
        dispatch(bottomBarActions.loadTab({ tabName: "orderSummary" }));
        return;
      default:
        dispatch(bottomBarActions.loadTab({ tabName: "chef" }));
        break;
    }
    dispatch(storieAction.setStorieFalse());
  };

  return (
    <div className="stories_wrapper single_stories_wrapper">
      <Stories onAllStoriesEnd={onAllStoriesEnd} stories={stories} />
    </div>
  );
};

export default SingleStoriePage;
