import React from "react";
import "./chef-card-slider.css";
import { isIOSDevice } from "../../utils/helper";

const ChefCardSlider = (props) => {

  return (
    <div style={{ position: "relative" }} className="slick-item">
      <div className="slider-item">{props.children}</div>
    </div>
  );
};

export default ChefCardSlider;
