import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chefActions } from "../../store/chefSlice";
import { S3_MENU_FOLDER } from "../../store/constants";
import { fetchDishBand } from "../../store/menuActions";
import "./story.css";
import { menuActions } from "../../store/menuSlice";
import { bottomBarActions } from "../../store/bottomBarSlice";
import mixpanel from "mixpanel-browser";
import BackBtnSvg from "../../assets/ReactSvg/BackBtnSvg";
// import clevertap from "clevertap-web-sdk";

const Story = (props) => {
  const { categoryImg, name, headers, description = "" } = props;
  const dispatch = useDispatch();
  const dish = useSelector(({ dish }) => dish);
  const [loaded, setLoaded] = useState(false);
  const onStoryBackClick = () => {
    dispatch(chefActions.onStoryBackClick());
    mixpanel.track("menu-stories-back-clicked");
    // clevertap.event.push("menu-stories-back-clicked");
  };

  const getTextForBand = (band) => {
    switch (band) {
      case "HIDDEN_GEM":
        return "Hidden Gems: 70-99% off";
      case "MIDDLE":
        return "Popular Platter: 50-70% off";
      case "TRENDING":
        return "Hot Buns: 40-50% off";
      case "NORMAL":
        return "No discount";
      default:
        break;
    }
  };

  const addToCard = () => {
    dispatch(menuActions.setIsMenuClicked(true));
    dispatch(bottomBarActions.loadTab({ tabName: "menu" }));
    dispatch(chefActions.onMenuClick(props.chefId));
    mixpanel.track("go-to-menu-clicked");
    // clevertap.event.push("go-to-menu-clicked");
  };

  useEffect(() => {
    if(!props.id) return
    dispatch(fetchDishBand(props.id));
  }, [dispatch]);

  useEffect(() => {
    if (loaded && props.isActive) {
      props.action("play");
    }
  }, [loaded]);

  const imageLoaded = () => {
    setLoaded(true);
  };

  return (
    <div>
      <div className="image-container">
        <img
          src={S3_MENU_FOLDER + props.img}
          className="story-image-container"
          loading="lazy"
          onLoad={imageLoaded}
        />
        <div className="gradient"></div>
      </div>
      {!loaded &&
        <div className="story-img-skeleton-loader"></div>
      }
      <div className="swiper_Top">
        <div className="swiper_Title">
          <div className="swiper_Title_img">
            <img src={categoryImg} className="storyImg" />
          </div>
          <p>{name}</p>
        </div>
        <div className="swiper_top_menu">
          <div className="swiper_top_back_btn">
            <button
              onClick={onStoryBackClick}
              type="button"
              style={{ height: 60 }}
              className="btn back-btn abs-pos">
              <BackBtnSvg />
            </button>
          </div>
          <div className="dishItems">
            <ul>{headers}</ul>
          </div>
        </div>
      </div>



      <div className="swipper_bottom">
        {description && <div style={{ marginBottom: 15 }}>{description}</div>}
        <div className="swipper_bottom_first">
          <div className="items_value">
            <div className="items_price">
              <h6>Original Price</h6>
            </div>
            <div className="plus_taxes">
              <h5>
                <span className="currency">&#8377;</span> {props.price}
              </h5>
              <p>plus taxes</p>
            </div>
          </div>
          <div onClick={addToCard} className="addcart_btn">
            <button>
              <p>Go to Menu</p>
              <span>+</span>
            </button>
          </div>
        </div>
        {dish.dishBand ? (
          <h6 className="media-dish-cap orange-text">
            {getTextForBand("HIDDEN_GEM")}
          </h6>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Story;