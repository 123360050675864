import * as React from 'react';
import { Renderer as RenderProp, Tester } from './../interfaces';

/* eslint-disable */
export const renderer: RenderProp = (props) => {
    React.useEffect(() => {
        props.action('pause');
    }, [props.story])
    const Content = props.story.originalContent;
    return <Content {...props} />
}

export const tester: Tester = (story) => {
    return {
        condition: !!story.content,
        priority: 2
    }
}

export default {
    renderer,
    tester
}
/* eslint-enable */
