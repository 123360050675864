import * as React from "react";
const CloseCrossSvg = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.3916 3.54536L11.9377 8.99982L17.3916 14.454C18.2028 15.2656 18.2028 16.5802 17.3916 17.3917C16.9862 17.7971 16.4548 17.9999 15.9236 17.9999C15.3916 17.9999 14.8601 17.7974 14.4551 17.3917L9.00003 11.9369L3.54536 17.3917C3.14007 17.797 2.6086 17.9998 2.07697 17.9998C1.5455 17.9998 1.01439 17.7973 0.60874 17.3917C-0.2025 16.5805 -0.2025 15.2658 0.60874 14.454L6.06248 8.99977L0.60843 3.54536C-0.20281 2.73412 -0.20281 1.4192 0.60843 0.607965C1.41951 -0.202655 2.73365 -0.202655 3.54505 0.607965L8.99997 6.06243L14.4545 0.607965C15.266 -0.202655 16.5803 -0.202655 17.3913 0.607965C18.2028 1.4192 18.2028 2.73412 17.3916 3.54536Z"
      fill="white"
    />
  </svg>
);
export default CloseCrossSvg;
