/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import {
  AccountDescWrapper,
  AccountDescriptionRow,
  AccountDescriptionRowV2,
} from "./styled";
import ReactTooltip from "react-tooltip";
import React, { useState } from "react";
import "../../components/base.css";
import mixpanel from "mixpanel-browser";
import { APP_URL } from "../../store/constants";
import whatsAppImg from "../../assets/whatsapp.png";
// import clevertap from "clevertap-web-sdk";

const MIN_CHEF_MENU_OPENED = 0;
const MAX_CHEF_MENU_OPENED = 8;
const MIN_DISH_PRICE_CHECK = 0;
const MAX_DISH_PRICE_CHECK = 20;

const UserAccountDescription = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [isCopiedReferralCode, setIsCopiedReferralCode] = useState(false);

  const openDishBookView = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "dishBook" }));
    mixpanel.track("dish-bookings-clicked");
    // clevertap.event.push("dish-bookings-clicked");
  };

  const openLegalView = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "legalView" }));
    mixpanel.track("legal-view-clicked");
    // clevertap.event.push("legal-view-clicked");
  };

  const onChatClick = () =>{
    let url = "https://wa.me/918655861798?text=Hey+Cuirato,+I+want+help+regarding+an+order.";
    url = window.encodeURI(url);
    window.open(url, "_blank");
  }

  const openFAQ = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "faq" }));
    mixpanel.track("faq-clicked");
    // clevertap.event.push("faq-clicked");
  };

  const openAbout = () => {
    dispatch(bottomBarActions.loadTab({ tabName: "about" }));
    mixpanel.track("about-us-clicked");
    // clevertap.event.push("about-us-clicked");
  };

  const getTotalBookedDish = () => {
    return user ? user.totalDishesBooked : 0;
  };

  const getFreeDishes = () => {
    return user ? (user.freeDishes ? user.freeDishes : 0) : 0;
  };

  const getChefMenuOpenedInPercentage = (isMaxChef) => {
    return (
      (((isMaxChef ? user.maxChefs : user.menuOpens) - MIN_CHEF_MENU_OPENED) *
        100) /
      (Math.max(MAX_CHEF_MENU_OPENED, user.menuOpens) - MIN_CHEF_MENU_OPENED)
    );
  };

  const getDishPriceInPercentage = () => {
    return (
      ((user.dishPriceChecked - MIN_DISH_PRICE_CHECK) * 100) /
      (Math.max(user.maxDishPrices, user.dishPriceChecked) -
        MIN_DISH_PRICE_CHECK)
    );
  };
  const copyReferralCode = () => {
    navigator.clipboard.writeText(user.referralCode);
    setIsCopiedReferralCode(true);
    mixpanel.track("referral-code-copied");
    // clevertap.event.push("referral-code-copied");
  };

  const handleShareButton = () => {
    // Check if navigator.share is supported by the browser
    if(window.ReactNativeWebView) { 
        window.ReactNativeWebView.postMessage(JSON.stringify({referralCode: user.referralCode, type: "SHARE_URL"}))
      }
    if (navigator.share) {
      console.log("Congrats! Your browser supports Web Share API");
      navigator
        .share({
          title: "Cuirato Referral Invite",
          text:
            "Hey! Here's an referral code to join Cuirato: " +
            user.referralCode +
            ". Pre-order your 2 dishes at a deep discount!",
          url: APP_URL,
        })
        .then(() => {
          console.log("Sharing successfull");
        })
        .catch(() => {
          console.log("Sharing failed");
        });
    } else {
      console.log("Sorry! Your browser does not support Web Share API");
    }
    mixpanel.track("share-clicked-account");
    // clevertap.event.push("share-clicked-account");
  };

  return (
    <AccountDescWrapper>
      <AccountDescriptionRow>
        <div className="heading-title">
          <p>Chefs Unlocked</p>
        </div>
        <div className="box-progress">
          <div className="box-container">
            <div
              className={
                user.menuOpens < MAX_CHEF_MENU_OPENED
                  ? "progress"
                  : "progress progress-hide"
              }
            >
              <div
                className="progress-bar bg-c-red"
                style={{ width: `${getChefMenuOpenedInPercentage(false)}%` }}
              >
                <div className="progress-value">{user.menuOpens}</div>
              </div>
              {user.menuOpens < MAX_CHEF_MENU_OPENED ? (
                <div
                  className="progress-bar bg-c-red info"
                  style={{ width: `${getChefMenuOpenedInPercentage(true)}%` }}
                >
                  <div className="progress-value">
                    <span>{user.maxChefs} </span>
                    <span
                      className="tooltip-span"
                      data-tip
                      data-for="maxChef"
                    ></span>
                  </div>

                  <ReactTooltip id="maxChef" type="warning" place="bottom">
                    <span>
                      You can unlock a maximum of {user.maxChefs} chefs
                    </span>
                  </ReactTooltip>
                </div>
              ) : (
                ""
              )}
              <div className="progress-left-value">{MIN_CHEF_MENU_OPENED}</div>
              {user.menuOpens < MAX_CHEF_MENU_OPENED ? (
                <div className="progress-right-value">
                  {MAX_CHEF_MENU_OPENED}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </AccountDescriptionRow>
      <AccountDescriptionRow>
        <div className="heading-title">
          <p>Dish prices checked</p>
        </div>
        <div className="box-progress">
          <div className="box-container">
            <div
              className={
                user.dishPriceChecked < user.maxDishPrices
                  ? "progress"
                  : "progress progress-hide"
              }
            >
              <div
                className="progress-bar bg-c-red"
                style={{ width: `${getDishPriceInPercentage()}%` }}
              >
                <div className="progress-value">{user.dishPriceChecked}</div>
              </div>
              <div className="progress-left-value">{MIN_DISH_PRICE_CHECK}</div>
              {user.dishPriceChecked < user.maxDishPrices ? (
                <div className="progress-right-value">{user.maxDishPrices}</div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </AccountDescriptionRow>
      <AccountDescriptionRow>
        <div className="heading-title heading-label" onClick={openDishBookView}>
          <p>Order history</p>
          {/* ({getTotalBookedDish() + " dishes booked"}) */}
          <button style={{left: 6}} onClick={() => {}} type="button" className="right-arrow-btn">
            <img src="images/icon-right.svg" alt="right" loading="lazy" />
          </button>
        </div>
      </AccountDescriptionRow>
      <AccountDescriptionRow>
        <div className="heading-title heading-label" onClick={openLegalView}>
          <p>Legal</p>
          <button style={{left: 6}} onClick={() => {}} type="button" className="right-arrow-btn">
            <img src="images/icon-right.svg" alt="right" loading="lazy" />
          </button>
        </div>
      </AccountDescriptionRow>
      <AccountDescriptionRow>
        <div className="heading-title heading-label" onClick={onChatClick} >
          <p className="customer-care-container"><span>Customer Support (Chat)</span> <img style={{ width: 18, height: 18 }} src={whatsAppImg} /> </p>
          <button style={{left: 6}} onClick={() => {}} type="button" className="right-arrow-btn">
            <img src="images/icon-right.svg" alt="right" loading="lazy" />
          </button>
        </div>
      </AccountDescriptionRow>
      {/* <AccountDescriptionRow>
        <div className="heading-title">
          <img
            className="free-dishes-icon"
            src="./images/Icons/Icon-02.svg"
            alt=""
          />
          <p>Free dishes</p>
          <b>{getFreeDishes()}</b>
        </div>
      </AccountDescriptionRow> */}
      <AccountDescriptionRow>
        <div className="referral-box">
          <div className="share-code-box">
            <div className="gift-box">
              <img
                className="gift-img"
                src="images/Icons/Icon-03.svg"
                alt="gift"
              />
            </div>
            <div>
              <div className="get-rewards">
                <span>Refer friends, Get 1 dish per referral</span>
              </div>
              <div className="d-flex align-items-center my-5">
                <span className="mx-3">Share your code -</span>
                <span className="mx-3">{user.referralCode}</span>
                <a className="mx-3" onClick={copyReferralCode}>
                  <img className="copy-img" src="images/copy.png" alt="copy" />
                </a>
              </div>
              <p className="referral-code-messgae">
                {isCopiedReferralCode ? "Referral code copied" : ""}
              </p>
            </div>
          </div>
          <div className="share-box">
            <a onClick={handleShareButton}>
              <img
                className="share-img"
                src="images/Icons/Icon-01.svg"
                alt=""
              />
              <span>Share</span>
            </a>
          </div>
        </div>
      </AccountDescriptionRow>
      {/* <AccountDescriptionRowV2 onClick={openFAQ}>
        <p>FAQ</p>
        <img src="images/icon-right.svg" alt="right" loading="lazy" />
      </AccountDescriptionRowV2>
      <AccountDescriptionRowV2 onClick={openAbout}>
        <p>About</p>
        <img src="images/icon-right.svg" alt="right" loading="lazy" />
      </AccountDescriptionRowV2> */}
      {/* {install && <AccountDescriptionRowV2 onClick={install}>
          <p>Install PWA</p>
      </AccountDescriptionRowV2>} */}

      <AccountDescriptionRowV2
        onClick={() => {
          mixpanel.track("logged-out");
          // clevertap.event.push("logged-out");
          localStorage.clear();
          window.location.reload();
        }}
      >
        <p>Logout</p>
      </AccountDescriptionRowV2>
    </AccountDescWrapper>
  );
};

export default UserAccountDescription;
