import * as React from "react";
const ClockSVGComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11S18.1 1 12 1Zm6 13h-8V4h3v7h5v3Z"
      fill="currentColor"
    />
  </svg>
);
export default ClockSVGComponent;
