import React from 'react';
import Sheet from 'react-modal-sheet';
import "./installModal.css"
import InstallEmoji from '../../assets/ReactSvg/InstallEmoji';
import CloseModalIcon from '../../assets/ReactSvg/CloseModalIcon';


const InstallModal = ({ isOpen, setOpen, setShowChoiceModal, foodChoiceState, setShowInstallModalAnswered }) => {

    const onDownloadClick = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.match(/Android/i)) {
            // Open Play Store link for Android
            window.open("https://play.google.com/store/apps/details?id=com.cuirato.app");
        } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
            // Redirect to App Store link for iOS
            window.open("https://apps.apple.com/in/app/cuirato/id6464570070");
        } else {
            // Handle other platforms or provide a fallback link
            console.log("Unsupported platform");
        }
    }
    return (
        <Sheet
            springConfig={{
                stiffness: 300,
                damping: 30,
                mass: 0.2
            }}
            isOpen={isOpen}
            onClose={() => {
                setOpen(false)
                setShowInstallModalAnswered(true)
                if (!foodChoiceState) {
                    setShowChoiceModal(true)
                }

            }}
            // snapPoints={[500, 0]}
            detent="content-height"
            className='install-modal-popup customReactSheet'
            onSnap={(snapIndex) =>
                console.log("> Current snap point index:", snapIndex)
            }
        >
            <Sheet.Container>
                <Sheet.Content>
                    <div onClick={() => {
                        setOpen(false)
                        setShowInstallModalAnswered(true)
                        if (!foodChoiceState) {
                            setShowChoiceModal(true)
                        }

                    }} className='closeIconInstallModal'>
                        <CloseModalIcon />
                    </div>
                    <div className='install-modal-wrapper'>
                        <div className='install-modal-emogy'>
                            <InstallEmoji />
                        </div>
                        <h5 className='install-app-label'>Download Our App</h5>
                        <div className='install-app-description'>Don't Miss Out on Satisfying Your Cravings!</div>
                        <button onClick={onDownloadClick} className='download-now-button-modal'>Download Now</button>
                        {/* <button style={{ marginTop: 10, background: "grey", borderColor: "#808080" }} onClick={() => {
                            setOpen(false)
                            setShowInstallModalAnswered(true)
                            if (!foodChoiceState) {
                                setShowChoiceModal(true)
                            }

                        }} className='download-now-button-modal'>Continue in web</button> */}
                    </div>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop onClick={() => {
                setOpen(false)
                setShowInstallModalAnswered(true)
                if (!foodChoiceState) {
                    setShowChoiceModal(true)
                }
            }} />
        </Sheet>
    )
}

export default InstallModal