import React from 'react';
import "./chef-cards.css";

const ChefOpeningHour = ({ time }) => {
    return (
        <div className='chef-timing-wrapper'>
            <span>{time?.day}</span>
            <span>{!time?.isAvailable ? `Weekly Off` : time?.time}</span>
        </div>
    )
}

export default ChefOpeningHour