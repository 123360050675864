import React from 'react'
import Privacy from '../../constant/Privacy&Policy/Privacy'

const PrivacyPage = () => {
  return (
    <div>
      <Privacy />
    </div>
  )
}

export default PrivacyPage