import axios from "axios";
import { API_TEST } from "./constants";
import { orderActions } from "./orderSlice";
const qs = require("qs");


export const fetchDriverLocation = (orderId) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axios.get(API_TEST + "getDeliveryCoordinates/" + orderId);
      if (response.status != 200) {
        throw new Error("Could not fetch Driver details");
      }
      const data = response.data;
      return data;
    };

    try {
      const data = await fetchData();
      dispatch(orderActions.deliveryDriverLocation({ data }));
    } catch {
      console.log();
      console.log("error while calling fetchDriverLocation");
      // show error on ui
    }
  };
};
