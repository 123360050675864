import * as React from "react";
const CookingIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={25}
    viewBox="0 0 25 25"
    enableBackground="new 0 0 128 128"
    xmlSpace="preserve"
    height={25}
    {...props}
  >
    <path
      fill="transparent"
      opacity={1}
      stroke="none"
      d="M0.195 14.453V0.207h25c0 4.94 0 9.893 -0.069 14.917 -0.346 -0.671 -0.925 -0.871 -1.661 -0.871 -7.452 0.008 -14.903 0.003 -22.355 0.013 -0.305 0.001 -0.61 0.122 -0.915 0.187m10.44 -8.015 -0.596 -2.557 -0.313 -0.003c-0.312 0.776 -0.799 1.533 -0.888 2.335 -0.091 0.82 0.206 1.684 0.334 2.528 0.122 0.808 0.246 1.616 0.389 2.562 1.872 -1.717 2.127 -2.526 1.074 -4.863M15.625 9.235 14.422 4.43c-0.776 0.544 -1.158 1.341 -1.121 2.255 0.062 1.562 0.241 3.12 0.398 4.677 0.014 0.14 0.227 0.261 0.55 0.607 0.506 -0.945 0.941 -1.755 1.375 -2.733M6.245 11.186c0.597 -0.851 0.78 -1.794 0.49 -2.781 -0.34 -1.155 -0.755 -2.288 -1.159 -3.493 0.035 -0.006 -0.222 -0.023 -0.254 0.054 -0.299 0.711 -0.762 1.432 -0.784 2.162 -0.037 1.224 0.204 2.459 0.352 3.686 0.044 0.361 0.169 0.712 0.257 1.068 0.34 -0.188 0.68 -0.377 1.098 -0.696z"
      strokeWidth={0.1953125}
    />
    <path
      fill="transparent"
      opacity={1}
      stroke="none"
      d="M25.195 16.797v8.39H0.195c0 -3.055 0 -6.119 0.071 -9.254 0.847 2.582 2.939 3.87 5.3 4.744 2.349 0.87 4.789 0.999 7.271 0.432 2.466 -0.564 4.65 -1.512 6.122 -3.701 0.141 -0.209 0.497 -0.399 0.742 -0.387 0.492 0.025 0.972 0.265 1.464 0.294 1.37 0.079 2.84 0.825 4.029 -0.518z"
      strokeWidth={0.1953125}
    />
    <path
      fill="#808285"
      opacity={1}
      stroke="none"
      d="M25.195 16.71c-1.189 1.43 -2.659 0.685 -4.029 0.605 -0.492 -0.029 -0.972 -0.269 -1.464 -0.294 -0.245 -0.013 -0.602 0.177 -0.742 0.387 -1.473 2.189 -3.656 3.137 -6.122 3.701 -2.482 0.568 -4.922 0.438 -7.271 -0.432C3.205 19.803 1.113 18.515 0.266 15.841 0.195 15.421 0.195 15.023 0.195 14.539c0.305 -0.151 0.61 -0.272 0.915 -0.273 7.452 -0.011 14.903 -0.005 22.355 -0.013 0.737 -0.001 1.316 0.199 1.661 0.962 0.069 0.482 0.069 0.945 0.069 1.495z"
      strokeWidth={0.1953125}
    />
    <path
      fill="#808285"
      opacity={1}
      stroke="none"
      d="M10.685 6.501c1.003 2.275 0.748 3.084 -1.124 4.801 -0.143 -0.945 -0.266 -1.753 -0.389 -2.562 -0.128 -0.845 -0.426 -1.708 -0.334 -2.528 0.089 -0.801 0.576 -1.558 0.888 -2.335l0.313 0.003c0.198 0.853 0.397 1.705 0.646 2.62z"
      strokeWidth={0.1953125}
    />
    <path
      fill="#808285"
      opacity={1}
      stroke="none"
      d="M15.625 9.319c-0.435 0.894 -0.869 1.704 -1.375 2.649 -0.323 -0.346 -0.536 -0.466 -0.55 -0.607 -0.157 -1.557 -0.336 -3.115 -0.398 -4.677 -0.037 -0.914 0.345 -1.711 1.121 -2.255 0.419 1.673 0.811 3.239 1.202 4.89z"
      strokeWidth={0.1953125}
    />
    <path
      fill="#808285"
      opacity={1}
      stroke="none"
      d="M6.206 11.251c-0.379 0.254 -0.719 0.442 -1.06 0.63 -0.088 -0.356 -0.213 -0.707 -0.257 -1.068 -0.148 -1.227 -0.389 -2.462 -0.352 -3.686 0.022 -0.73 0.486 -1.451 0.784 -2.162 0.032 -0.077 0.289 -0.06 0.254 -0.054 0.404 1.205 0.819 2.338 1.159 3.493 0.29 0.987 0.107 1.93 -0.529 2.847z"
      strokeWidth={0.1953125}
    />
  </svg>
);
export default CookingIcon;
